import React, { useState, useEffect } from 'react';
import LeaderboardTable from '../src/components/LeaderboardTable.js';
import CountdownTimer from './components/CountdownTimer';
import { getLeagueInfoBySummonerId, getAccountByRiotId, getSummonerByEncryptedPuuid } from './api/RiotApiClient';
import { getDeadlineInCST } from './utils/timeUtils';

const rankValues = {
  'CHALLENGER': 1, 'GRANDMASTER': 2, 'MASTER': 3, 'DIAMOND': 4,
  'EMERALD': 5, 'PLATINUM': 6, 'GOLD': 7, 'SILVER': 8,
  'BRONZE': 9, 'IRON': 10
};
const divisionValues = { 'I': 1, 'II': 2, 'III': 3, 'IV': 4 };

const getRankValue = (rank) => {
  const [rankName, division] = rank.toUpperCase().split(' ');
  const rankVal = rankValues[rankName] * 100 + divisionValues[division];
  return rankVal;
};

const comparePlayers = (playerA, playerB) => {
  const rankValueA = getRankValue(playerA.rank);
  const rankValueB = getRankValue(playerB.rank);

  if (rankValueA !== rankValueB) {
    return rankValueA - rankValueB; // Lower value means higher rank
  }

  // If ranks are the same, compare by league points (higher is better)
  return playerB.leaguePoints - playerA.leaguePoints;
};

//const playerSummonerNames = ["Piiter Magno#LAN"];
const playerSummonerPuuids = {
"Piiter Magno#LAN": "dzmiEszTABfvdWYItIWKsSeWHTw_295FWuKjLYeUAuElRq6tbLabLcS-TCulABXmi0JAeJORR-KSGg", 
"Villa#SAN": "SkjiaZpjbFjQA3eZjYbF17li001HftTTy9XC9Ho-L5zk_aflOoQ7mUD3An_FWgMnsSnzZ-6HFCdY2A", 
"BARLAENX#LAN": "Crpdj7RSmY5S3PjpBTSSiooyqWkD2JgMuP_GZc7NYjH-izDIlgvvOj56PWZ6pBBgpdmHQho-y1iNyA", 
"Camposjrz#LAN": "-vHb6-Vb452gpJUZNAphij90EeoWd8SSjGRRDlGr6JtGI9COO5-rZwwTOk65wqUngm2UCiBffA6fxA", 
"Mikekbza#15269": "Mpm_23OPQToPb4trrP42Zl6k-lK6_qQQqC1z_CThPuZTH3Pu1YniepwYDYupHgz5nmVkmltU09LpDw",
"Esbuenojugarlol": "Cmct8lbfsS2TeW3uc3zbMOaf7TwDx-uiDWB_jTsupVq2J2ZxbRERYU8aBS1VH8Sevh7u4BHWf1lOJg",
"SkyOctaxs#LAN": "BqqTYZJO5_y75svw1R-mIyh4yloy42wcW1J6gMtUATH5jOByYDoWdAwdgSjeI2G5pZolB8_b-sza9g"
}

function App() {
  const [players, setPlayers] = useState([]);
  const deadline = getDeadlineInCST('November 29, 2024 23:59:59'); 

  useEffect(() => {
    const fetchPlayers = async () => {
      const fetchedPlayers = [];

      for (const gameName in playerSummonerPuuids) {
        try {
          //const [summName, tagLine] = summonerName.split('#');
          //const accountResponse = await getAccountByRiotId(summName, tagLine);
          
          const summonerResponse = await getSummonerByEncryptedPuuid(playerSummonerPuuids[gameName]);
          const summonerData = summonerResponse.data;
          
          if (summonerData) {
            const leagueResponse = await getLeagueInfoBySummonerId(summonerData.id);

            if (leagueResponse && leagueResponse.data.length) {
              const rankedSoloData = leagueResponse.data.find(entry => entry.queueType === "RANKED_SOLO_5x5");

              if (rankedSoloData) {
                const totalGames = rankedSoloData.wins + rankedSoloData.losses;
                const winRate = totalGames > 0 ? ((rankedSoloData.wins / totalGames) * 100).toFixed(2) : '0.00';

                fetchedPlayers.push({
                  id: summonerData.id,
                  username: gameName,
                  wins: rankedSoloData.wins,
                  losses: rankedSoloData.losses,
                  winRate: winRate,
                  rank: rankedSoloData.tier + ' ' + rankedSoloData.rank,
                  leaguePoints: rankedSoloData.leaguePoints,
                  puuid: summonerData.puuid
                });
              }
            }
          }
        } catch (error) {
          console.error('Error fetching data for summoner:', gameName, error);
        }
      }
      fetchedPlayers.sort(comparePlayers);
      setPlayers(fetchedPlayers);
    };

    fetchPlayers();
  }, []);

  return (
    <div className="App">
      <h1>Ferumbras League of Legends Leaderboard</h1>
      <CountdownTimer deadline={deadline} />
      <LeaderboardTable players={players} />
    </div>
  );
}

export default App;

