import axios from 'axios';

const API_KEY = process.env.REACT_APP_API_KEY;
const RIOT_API_URL = 'https://americas.api.riotgames.com';
const RIOT_LA1_API_URL = "https://la1.api.riotgames.com";

const getAccountByPuuid = (puuid) => {
  return axios.get(`${RIOT_API_URL}/riot/account/v1/accounts/by-puuid/${puuid}?api_key=${API_KEY}`);
};

const getLeagueInfoBySummonerId = (encryptedSummonerId) => {
  return axios.get(`${RIOT_LA1_API_URL}/lol/league/v4/entries/by-summoner/${encryptedSummonerId}?api_key=${API_KEY}`);
};

const getLeagueInfoBySummonerName = (summonerName) => {
  return axios.get(`${RIOT_LA1_API_URL}/lol/summoner/v4/summoners/by-name/${summonerName}?api_key=${API_KEY}`);
};

const getMatchIdsByPuuid = (puuid, queueId, count) => {
  return axios.get(`${RIOT_API_URL}/lol/match/v5/matches/by-puuid/${puuid}/ids?queue=${queueId}&start=0&count=${count}&api_key=${API_KEY}`);
};

const getMatchById = (id) => {
  return axios.get(`${RIOT_API_URL}/lol/match/v5/matches/${id}?api_key=${API_KEY}`);
};

const getAccountByRiotId = (gameName, tagLine) => {
  return axios.get(`${RIOT_API_URL}/riot/account/v1/accounts/by-riot-id/${gameName}/${tagLine}?api_key=${API_KEY}`);
};

const getSummonerByEncryptedPuuid = (encryptedPUUID) => {
  return axios.get(`${RIOT_LA1_API_URL}/lol/summoner/v4/summoners/by-puuid/${encryptedPUUID}?api_key=${API_KEY}`);
};

export { getAccountByPuuid, getLeagueInfoBySummonerId, getLeagueInfoBySummonerName, getMatchIdsByPuuid, getMatchById, getAccountByRiotId, getSummonerByEncryptedPuuid };

