import React, { useState } from 'react';
import LeaderboardRow from './LeaderboardRow';
import '../styles/LeaderboardTable.css'; 

const LeaderboardTable = ({ players }) => {
  const [openRow, setOpenRow] = useState(null);

  const handleToggle = (playerId) => {
    setOpenRow(prevOpenRow => (prevOpenRow === playerId ? null : playerId));
  };

  return (
    <table>
      <thead>
        <tr>
	        <th>#</th> {/* Numbering column header */}
          <th>Username</th>
          <th>Rank</th>
          <th>Score (W-L)</th>
          <th>Win Rate</th>
          <th>League Points</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {players.map((player, index) => (
          <LeaderboardRow
            key={player.id}
            rank={index + 1}
            player={player}
            isOpen={openRow === player.id}
            onToggle={handleToggle}
          />
        ))}
      </tbody>
    </table>
  );
};

export default LeaderboardTable;

