// src/components/CountdownTimer.js
import React, { useState, useEffect } from 'react';
import { getCurrentTimeInCST, getDeadlineInCST } from '../utils/timeUtils';

const CountdownTimer = ({ deadline }) => {
  const calculateTimeRemaining = () => {
    const now = getCurrentTimeInCST();
    const timeRemaining = deadline - now;

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [deadline]);

  if (timeRemaining.days < 0) {
    return <div className="timer">Deadline Reached</div>;
  }

  return (
    <div className="timer">
      {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s
    </div>
  );
};

export default CountdownTimer;

