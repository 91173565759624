// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #04AA6D;
    color: white;
}

img {
    width: 50px; 
    height: auto;
    border-radius: 50%; 
}
.expandedContent {

}
`, "",{"version":3,"sources":["webpack://./src/styles/LeaderboardTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;;AAEA","sourcesContent":["table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\nth, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\nth {\n    background-color: #04AA6D;\n    color: white;\n}\n\nimg {\n    width: 50px; \n    height: auto;\n    border-radius: 50%; \n}\n.expandedContent {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
