// src/utils/timeUtils.js

export const getCurrentTimeInCST = () => {
  const now = new Date();
  const options = { timeZone: 'America/Chicago', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const cstString = now.toLocaleString('en-US', options);
  const [date, time] = cstString.split(', ');
  const [month, day, year] = date.split('/');
  const [hour, minute, second] = time.split(':');
  return new Date(year, month - 1, day, hour, minute, second).getTime();
};

export const getDeadlineInCST = (deadlineString) => {
  const deadline = new Date(deadlineString);
  const options = { timeZone: 'America/Chicago', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const cstString = deadline.toLocaleString('en-US', options);
  const [date, time] = cstString.split(', ');
  const [month, day, year] = date.split('/');
  const [hour, minute, second] = time.split(':');
  return new Date(year, month - 1, day, hour, minute, second).getTime();
};

