import React, { useState, useEffect } from 'react';
import { getMatchIdsByPuuid, getMatchById } from '../api/RiotApiClient';

const LeaderboardRow = ({ rank, player, isOpen, onToggle}) => {

  const [matchDetails, setMatchDetails] = useState([]);

  useEffect(() => {
    const fetchMatchDetails = async () => {
      if (isOpen && player.puuid) {
        try {
          const matchIdsResponse = await getMatchIdsByPuuid(player.puuid, 420, 5);
          if (matchIdsResponse.data) {
            const matchDetailsPromises = matchIdsResponse.data.map(matchId => getMatchById(matchId));
            const matches = await Promise.all(matchDetailsPromises);
            const filteredMatchDetails = matches
              .map(match => match.data.info)
              .map(info => info.participants.find(participant => participant.puuid === player.puuid));
            setMatchDetails(filteredMatchDetails);
          }
        } catch (error) {
          console.error('Error fetching match details:', error);
        }
      }
    };

    fetchMatchDetails();
  }, [isOpen, player.puuid]);

  // Define a function to determine the row color
  const getRowColor = (detail) => {
    if (detail.gameEndedInEarlySurrender) {
      return 'gray'; // Remake color
    }
    return detail.win ? 'blue' : 'red'; // Win/Loss color
  };

  return (
    <>
      <tr>
        <td>{rank}</td>
        <td>{player.username}</td>
        <td>{player.rank}</td>
        <td>{`${player.wins} - ${player.losses}`}</td>
        <td>{player.winRate}%</td>
        <td>{player.leaguePoints}</td>
        <td>
          <button onClick={() => onToggle(player.id)}>Toggle Matches</button>
        </td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan="7">
            <table>
              <thead>
                {/* <tr>
                  <th>Champion</th>
                  <th>Kills</th>
                  <th>Deaths</th>
                  <th>Assists</th>
                </tr> */}
              </thead>
              <tbody>
                {matchDetails.map((detail, index) => (
                  <tr key={index} style={{ backgroundColor: getRowColor(detail) }}>
                    <td>{detail.championName}</td>
                    <td>{detail.kills}/{detail.deaths}/{detail.assists}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default LeaderboardRow;

